body {
    font-family: $font-primary;
    font-weight: 200;
}

p {
    font-weight: 200;

}

.intro-text h1 {
    font-size: 6rem;
    margin-bottom: 60px;
    line-height: 6.2rem;
    color: $color-dark;
  }

h1 {
    font-family: $font-headings;
    font-weight: normal;
}

h2 {
    font-family: $font-headings;
    font-weight: normal;
}

.cls-2, .cls-3 {
    font-family: $font-logo;
  }

.navbar-links {
    font-family: $font-headings;
    letter-spacing: 0.025em;
    font-size: 1.3rem;
}