

.logo-section {
  text-align: center;
  padding: 20px;
  background-color: #fff;
}

.logo-grid {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 10px;
    margin: auto;

    .logo-card {
        height: 200px;
        display:flex;
        justify-content: center;
    align-items: center;
    background-color: #fff; 
    &:hover .logo-image {
      filter: grayscale(0%);
      transform: scale(1.1);
      cursor: pointer;
    }
    

      
  
      .logo-image {
        max-width: 120px;
        height: 80px;
        object-fit: contain; /* Ensure the logo scales properly without distortion */
        filter: grayscale(100%); /* Make image black and white by default */
    transition: filter 0.3s ease; /* Smooth transition between grayscale and color */

      }}}
  

    

    @media (min-width: 1024px) {
        .logo-grid {
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: wrap;
          justify-items: center;
        }
      
        .logo-card {
          flex: 1 1 calc(33.333% - 6px); /* 3 cards per row, with a gap of 6px */
          max-width: calc(33.333% - 6px); /* Ensures the cards have a fixed width */
    
          
        }
      }
  

 
