@media (max-width: 768px) {
  #sara-logo {
    max-width: 200px; /* Reduce size for smaller screens */
  }
}

#sara-logo {
  max-width: 360px;
  height: auto;
  z-index: 2;
  padding: 30px;

  .cls_1 {
    font-family: futura-pt, sans-serif;
  }

  .cls_3, .cls_4 {
    font-family: $font-logo;
    fill: $color-white;
  }

  .cls_2 {
    font-family: $font-headings;
  }

  
}

.hero-section {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  background: url('../../assets/images/soverom_londonII.jpg') no-repeat center center/cover;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(39, 29, 22, 0.6);
  display: flex;
  justify-content: center;
  z-index: 1;
}


  

  
  .centered {
    text-align: center;
    span {
      font-size: 3rem;
    }
  }
  

  
