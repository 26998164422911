/* Navbar for all screen sizes */
.navbar {
  position: fixed; /* Stick the navbar to the top */
  top: 0;
  left: 0;
  width: 100vw; /* Full width across viewport */
  z-index: 1000; /* Ensure it's above other content */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-light;
  padding: 15px 20px;

}



/* Hamburger Menu */
.hamburger-menu {
  display: block;
  font-size: 1.8rem;
  color: $color-dark;
  cursor: pointer;
  z-index: 2; /* Ensure it's above sliding menu */
}

/* Mobile Navbar Links (initially off-screen) */
.navbar-links {
  position: fixed;
  top: 82px; /* Below the navbar */
  left: 0;
  height: calc(100%);
  width: 250px; /* Slide-out menu width */
  background-color: $color-light;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 20px;
  padding-top: 2rem;
  z-index: 1;
  transform: translateX(-100%); /* Off-screen initially */
  transition: transform 0.3s ease; /* Smooth slide-in */
}

/* Links inside the navbar */
.navbar-links a {
  color: $color-dark;
  text-decoration: none;
  font-family: $font-headings;
  font-weight: normal;

  &:hover {
    color: $color-brown;
    transition: color 0.3s ease-in-out;
  }
}

/* Make sure the logo is sized correctly */
#sara-logo-nav {
  width: 60px;
  height: auto;
  fill: $color-white;
  font-family: $font-logo;
  display: block;
}




/* Navbar links for larger screens */
@media only screen and (min-width: 1024px) {
  .navbar {
    padding: 15px 30px;
    justify-content: space-between;

    /* Hide the hamburger menu on large screens */
    .hamburger-menu {
      display: none;
    }

    /* Show links in a row on large screens */
    .navbar-links {
      position: static;
      display: flex;
      flex-direction: row;
      justify-content: flex-end; /* Align links to the right */
      padding: 0;
      gap: 20px;
      margin-left: auto; /* Pushes links to the right */
      width: auto;
      transform: none; /* No sliding effect for large screens */
      transition: none; /* Remove transition for large screens */
    }
  }
}