.about-page {
    padding: 60px 40px 80px 40px;
    max-width: 1200px;
    margin: 0 auto;
    color: #333;

    .about-section {
      padding-top: 40px;
      max-width: 1200px;
      margin: 0 auto;
    }
    
    .about-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      gap: 20px;
    
      @media (max-width: 768px) {
        grid-template-columns: 1fr; /* Stack columns on smaller screens */
        text-align: center;
      }
    }
    
    .about-text {


      h3 {
        font-size: 1.2rem;
        font-weight: 200;
        color: #888;
      }
    
      h1 {
        font-size: 3rem;
        margin-bottom: 20px;
        line-height: 1.2;
      }
    
      p {
        font-size: 1.2rem;
        line-height: 1.6;
        color: #666;
      }
    }
    
    .about-image {
      width: 100%; /* Make the container responsive */
      position: relative; /* Position relative for absolute child positioning */
      padding-bottom: 100%; /* Set padding-bottom to create a square aspect ratio */
      overflow: hidden; /* Hide overflow to maintain square shape */
    }
    
    .about-image img {
      position: absolute; /* Position the image absolutely */
      top: 0; /* Align to the top */
      left: 0; /* Align to the left */
      width: 100%; /* Fill the container width */
      height: 100%; /* Fill the container height */
      object-fit: cover; /* Maintain aspect ratio and cover the container */
      padding: 40px;
      border-radius: 50%;
      
      /* Makes the image black and white */
      filter: grayscale(100%);
    }
    
    /* Responsive styling */
    @media (max-width: 768px) {
      .about-image {
        margin: 20px auto; /* Center image on mobile */
      }
    }

    .education-skills-section {
      padding-bottom: 80px;
  
      .education-header {
        margin-bottom: 50px;
  
        h2 {
    
          margin-bottom: 10px;
      
        }
  
        p {
          font-size: 1.2rem;
          color: #777;
    
        }
      }
      .education-cards :nth-child(2){
        margin-top: 20px;

      }

      .education-cards .education-card:nth-child(1){
       background-color: $color-light;

      }

  
        .education-card {
          background-color: $color-main;
          padding: 30px;
          height: 200px;

  
          h3 {
            margin-bottom: 15px;
            font-weight: normal;
         
            
          }
  
          p {
            font-size: 1rem;
            color: #555;
     
          }
        }
      }
    }
  
  
    /* Skills Section */

  
    .about-skills {
      padding-top: 30px;


    
      .skill {

        margin-bottom: 20px;

      
  
        p {
          margin-bottom: 5px;
        }
  
        .skill-bar {
          width: 100%;
          background-color: #f8f8f8;
          height: 10px;
          position: relative;
  
          .skill-level {
            background-color: $color-main;
            height: 100%;
            transition: width 0.4s ease;
          }
        }
      }
    }
  
    /* Interests Section */
    .about-interests {
  
      p {
        line-height: 1.8;
        font-size: 1.1rem;
      }

      h2 {
        padding-bottom: 20px;
      }
    }
  

  @media (min-width: 1024px) {

    .about-text {

      padding-right: 40px;
    }

    .about-skills {

      padding-top: 0;
    }

    .education-card{

    max-width: 400px;

    }

    .education-skills-section {
      display: flex;
      gap:  40px;

      .about-education,  .about-skills
      {
        flex: 1 1 50%; /* Each item will take up 50% of the container width */
  box-sizing: border-box; /* Ensures padding/border do not affect the width */
      }

   
  }
  }
  .about-gallery {
    margin-top: 40px;
  

  
  }
