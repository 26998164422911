/* Gallery Grid Styles */

.about-gallery {
    h2 {
        font-size: 1.2rem;
    }
    p {
        padding-bottom: 20px;
    }

  padding: 30px 0;
}
.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .gallery-item {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .gallery-item:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }
  
  .gallery-img {
    width: 100%;
    height: 300px; /* Set a fixed height */
    object-fit: cover; /* Cover the area while maintaining aspect ratio */
  }
  
  /* Lightbox Styles */
  .lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .lightbox-img {
    max-width: 90%;
    max-height: 90%;
  }
  
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2rem;
    color: white;
    cursor: pointer;
  }

  .prev-button {
    position: absolute;
    left: 20px;
  }
  
  .next-button {
    position: absolute;
    right: 20px;
  }

  .next-button, .prev-button {
    color: $color-light;
    cursor: pointer;


    .material-symbols-outlined   {
        font-size: 3rem;
    }

  }