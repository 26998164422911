.featured-projects, .all-projects {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 equal columns for small cards */
  grid-gap: 20px; /* Adjust gap between cards */
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

/* Large card */
.featured-projects .card:nth-child(1),
.featured-projects .card:nth-child(4),
.featured-projects .card:nth-child(7),
.all-projects .card:nth-child(1),
.all-projects .card:nth-child(4),
.all-projects .card:nth-child(7) {
  grid-column: span 2; /* Make this card span across both columns */
}

/* Card styles */
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Ensure equal height */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
  background-color: #fff;
  padding: 10px 0;
  overflow: hidden; /* To prevent content from overflowing on hover */

}

/* Hover Effect */
.card:hover {
  transform: scale(1.03); /* Slightly enlarge the card on hover */
}

.card-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.card-content {
  padding-bottom: 20px;
}

.card-title {
  font-size: 1.2rem;
}

.card-category {
  font-size: 1rem;
  color: $color-dark;
  margin-top: 5px;
}

/* Responsive design for mobile */
@media (max-width: 1024px) {
  .featured-projects, .all-projects {
    grid-template-columns: 1fr; /* Single column on mobile */
    grid-gap: 20px;
    display: block;
  }

  /* Large card on mobile */
  .featured-projects .card:nth-child(1),
  .featured-projects .card:nth-child(4),
  .featured-projects .card:nth-child(7),
  .all-projects .card:nth-child(1),
  .all-projects .card:nth-child(4),
  .all-projects .card:nth-child(7) {
    grid-column: span 1; /* Revert to single-column layout */
  }
}



