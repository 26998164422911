*, body {
  box-sizing: border-box;
  margin: 0;
}

.all-projects-page {
  .section-header {
    padding: 20px;
  }

}

.social-icons {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1000;
  padding: 0 30px;

  svg {
    width: 0.8em
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 20px;

      a {
        color: $color-brown;
        font-size: 1.2rem;
        transition: color 0.3s;

        &:hover {
          color: $color-main; /* Accent color */
        }
      }
    }
  }

  @media (max-width: 768px) {
    display: none; /* Hide on mobile */
  }
}

body {

background-color: #fff;

}



html, body {
  height:100vh;
  position: relative;
  width: 100%;
}

.wrapper {
  margin-top: 82px;
}

.App {
  min-height: 100vh;
  position: relative;

}  

  a {
    text-decoration: none;
    color: $color-dark;
    transition: color 0.3s ease;
  }

  .btn {
    padding: 10px 20px;
    font-size: 1rem;
    font-family: $font-primary;
    text-align: center;
    color: $color-dark;
    background-color: $color-main;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    align-self: center;
    align-items: center;
    display: flex;
    transition: background-color 0.3s ease, transform 0.2s ease;
    
    &:hover {
      background-color: $color-light;
    }
  
    &:active {
      background-color: $color-brown;
      transform: translateY(0); /* Return to normal on click */
    }
  
    &:focus {
      outline: none;
    }
  }
  
  
    .section-header {
    padding: 40px 0;
    max-width: 1200px;
    margin: auto;

    h1 {
      padding: 40px 0 20px 0;
    }
  }


  .view-all-projects-section {
    display: flex;
    justify-content: end;
    padding: 20px;
  }
  

  .featured-projects-title {
    text-transform: uppercase;
  }
  
  .featured-projects-title,
 



  .projects-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  footer {
left:0;
bottom:0;
right:0;
padding: 20px;
background-color: $color-light;
color: $color-black;
font-family: $font-headings;
font-size: 1.2rem;

li {
  list-style: none;
}


}

.footer-icons {
display: flex;

li {
padding: 1rem 1rem 0 0;
}

}

@media (min-width: 380px) {
  footer {
    font-size: 1.5rem;
  }
}



@media (min-width: 1024px) {

  .view-all-projects-section {
max-width: 1200px;
margin: auto;
  }

  .all-projects-page {
    max-width: 1200px;
    margin: 0 auto;
    color: #333;

  }

  .section-header {
    padding: 40px 0;
  }

footer {
    display: flex;
    justify-content: space-between;
    padding: 30px;

}
.footer-icons {
    padding: 1rem;
}


}

 

