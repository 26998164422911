.project-detail-container {
  display: flex;
  flex-direction: row;
  gap: 40px;
  max-width: 1200px;
  margin: auto;
  padding: 40px;
  color: #333;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 20px;
  }

  .project-info {
    min-width: 300px;
    flex: 1;

    h1.project-title {
      font-size: 2.5rem;
      margin-bottom: 10px;
    }

    .project-year {
      font-size: 1.2rem;
      color: #666;
      margin-bottom: 20px;
    }

    .project-skills,
    .project-description,
    .project-learning {
      margin-bottom: 40px;

      h2 {
        font-size: 1.8rem;
        margin-bottom: 10px;
      }

      p,
      li {
        line-height: 1.6;
      }

      ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        :nth-child(1), :nth-child(4)  {
          border: 1px solid;
          border-color: $color-brown;
        }

        :nth-child(2) {
          background-color: $color-main;
        }
        :nth-child(3) {
          background-color: $color-light;
        }

        li {
          padding: 5px 15px;
          border-radius: 20px;
          font-size: 0.8rem;

        }
      }
    }
  }

  .project-images {
    flex: 2;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    gap: 30px;
    
    .project-image img {
      width: 100%;
      object-fit: cover;
    }
  }

  .project-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    a {
      display: flex;
      text-decoration: none;
      color: #333;

      &:hover {
        color: $color-main;
      }
    }
  }
}

/* Full-width main image for mobile, constrained properly */
.project-main-image {
  width: 100vw; /* Make sure it takes up the full viewport width */
  max-width: 100%; /* Prevents overflow */
  margin: 0 auto;
  display: none; /* Hidden on desktop by default */
  
  img {
    width: 100%; /* Ensure the image fits the full width of its container */
    height: auto;
    object-fit: cover;
  }

  @media (max-width: 1024px) {
    display: block; /* Display the main image on mobile screens */
  }
}

.project-subheader {
  font-weight: 400;
  display: flex;
  font-size: 0.8rem;

  p {
    padding-left: 10px;
  }
}