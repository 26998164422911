// typography
@import url("https://use.typekit.net/wnn5htd.css");
@import url('https://fonts.googleapis.com/css2?family=Afacad+Flux:wght@100..1000&display=swap');

$font-primary: "Poppins", sans-serif;
$font-headings: "the-seasons", sans-serif;
$font-logo: bely-display, sans-serif;




// colors

$color-main: #DFD6C9;
$color-light: #EFEDE7;
$color-dark: #2b2a29;
$color-brown: #B5A69A;
$color-white: #FFFEFA;
$color-black: #2e2e2e;

//icons
